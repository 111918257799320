body {
    margin: 0;
    font-family: sans-serif;
}

main {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

#map {
    height: 100%;
}

#filter-form, #shift-indicator {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
    background-color: white;
    border: 1px solid grey;
    border-radius: 2px;
    width: 400px;
    overflow: hidden;
}

#shift-indicator {
    width: 200px;
}

#filter-form-header {
    padding: 10px;
}

.expand-filters {
    float: right;
}

#filter-form-content {
    height: 0px;
    padding-bottom: 5px;
}

#filter-form-content > div {
    padding: 8px 30px 8px 15px;
}

#filter-form-content > div.select-wrapper {
    padding: 8px 15px;
}

#filter-form.expanded  #filter-form-content{
    height: 100%;
}

#collapse-text {
    display: none;
}

#filter-form.expanded  #collapse-text {
    display: block;
}

#filter-form.expanded  #expand-text {
    display: none;
}

#filter-form input[type="text"], #filter-form input[type="date"], #filter-form input[type="datetime-local"], #filter-form input[type="time"] {
    margin-top: 5px;
    border: 1px solid #c3c3c3;
    height: 22px;
    line-height: 36px;
    width: 100%;
    padding: 5px;
    max-width: 360px;
    border-radius: 3px;
    background: #fff;
}

table {
    border-spacing: 0;
    margin-top: 6px;
    border: solid 0.5px black;
}
th {
    text-align: right;
}
tr * {
    border: 0.5px solid grey;
    padding: 3px;
    margin: 0;
}

.select2.select2-container {
    margin-top: 5px;
}

@media (max-width: 1200px) {
    #filter-form {
        bottom: 40px;
        left: 10px;
        right: 10px;
        top: auto;
        width: auto;
    }

    #shift-indicator {
        bottom: 15px;
        top: auto;
        width: auto;
    }
}
